<template>
  <main>
    <ul class="c-nav c-nav--sub-nav o-list-inline">
      <li
        class="c-nav__list-item o-list-inline__item"
        v-for="subpage in subPages"
        :key="subpage.path"
      >
        <router-link class="c-nav__item" :to="`${rootPath}/${subpage.path}`">
          {{ subpage.name }} </router-link
        >&nbsp;
      </li>
    </ul>
    <section class="c-content">
      <h1 class="c-content__title">{{ $route.name }}</h1>
      <div
        class="o-layout c-content__main-image-wrapper u-1-of-2-at-large"
        v-if="introPage"
      >
        <div class="o-layout__item u-1-of-2 ">
          <img
            class="c-content__main-image"
            :src="`${$publicPath}/assets/girls-injuries.png`"
            alt=""
          />
        </div>
        <div class="o-layout__item u-1-of-2">
          <img
            class="c-content__main-image"
            :src="`${$publicPath}/assets/boys-injuries.png`"
            alt=""
          />
        </div>
      </div>
      <div v-if="introPage" class="c-content__body o-layout">
        <div class="o-layout__item c-content__text" v-html="htmlContent" />
      </div>
      <router-view />
    </section>
  </main>
</template>

<script>
import appRoutes from "@/appRoutes";
import content from "@/content";

export default {
  name: "Injuries",
  components: {},
  data() {
    return {
      htmlContent: "",
      htmlContentReferences: "",
      introPage: false
    };
  },
  mounted() {
    this.setContentText(0);
    this.introPage = this.isIntroPage();
  },
  methods: {
    setContentText(index) {
      console.log("index is ", index, this.$route.name.toLowerCase());
      // console.log("this.pageType", this, index, this.subPages, this.rootPath);
      // console.log("content[this.pageType]", content[this.pageType]);

      content["injuries"].intro.then(response => {
        if (response) {
          this.htmlContent = response.default.replaceAll(
            "/assets",
            `${this.$publicPath}/assets`
          );
          // console.log(response.default);
        }
      });

      // content["injuries"].references.then(response => {
      //   if (response) {
      //     this.htmlContentReferences = response.default;
      //     // console.log(response.default);
      //   }
      // });
    },
    isIntroPage() {
      // console.log(this.$route.name.toLowerCase());
      // console.log('is intro', this.$route.meta.id, this.$route.path, this.rootPath);
      return this.$route.meta.id === "injuries";
    }
  },
  computed: {
    rootPath() {
      return appRoutes[2].path;
    },
    subPages() {
      return appRoutes[2].children;
    }
  },
  watch: {
    // $route(newValue, oldValue) {
    //   this.introPage = this.isIntroPage();
    // },
    $route: {
      handler: function(to, from) {
        this.introPage = this.isIntroPage();

        this.$nextTick(() => {
          this.setContentText(0);
        });
      },
      immediate: false
    }
  }
};
</script>

<style scoped></style>
